import { useEffect, useState } from 'react'
import { ethers } from 'ethers'
import tokenabi from 'config/abi/cake.json'
import routerabi from './routerabi.json'
/**
 * Due to Cors the api was forked and a proxy was created
 * @see https://github.com/pancakeswap/gatsby-pancake-api/commit/e811b67a43ccc41edd4a0fa1ee704b2f510aa0ba
 */

const useGetPriceDataCustom = async (token) => {

    try{
        console.log("FIRED")
        const provider = new ethers.providers.JsonRpcProvider('https://bsc-dataseed.binance.org/');
        const contract = new ethers.Contract(token, tokenabi, provider)
        const decimals = await contract.decimals() 
        const amount = 1 * 10 ** decimals;

        // get price in BUSD
        const contract2 = new ethers.Contract('0x10ED43C718714eb63d5aA57B78B54704E256024E', routerabi, provider)
        let amountsOut = await contract2.getAmountsOut(amount, [token,'0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c','0xe9e7cea3dedca5984780bafc599bd69add087d56'])
        console.log('amounts out is "', amountsOut[2].toString())
        amountsOut = amountsOut[2].toString() / 10 ** 18;
        
        return amountsOut;
    }catch(err){
        console.log(err)
        return "0";
    }

}

export default useGetPriceDataCustom
