import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from '@pancakeswap/uikit/dist/theme'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Kanit', sans-serif;
  }
  body {
    // background: linear-gradient(0deg, #000,white);
    background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
    img {
      height: auto;
      max-width: 100%;
    }
  }
`

export default GlobalStyle
